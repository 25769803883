import { ServiceResponse } from './../responses/service-response';
import { HttpClient, HttpErrorResponse, HttpResponse, HttpHeaders, HttpEventType } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { identity, Observable, of, timer } from 'rxjs';
import { catchError, delay, map, retry, shareReplay, switchMap, take, tap, timeout } from 'rxjs/operators';
import { BaseError } from '../messages/base-error';
import { ServiceRequest } from '../requests/service-request';
import { EndPointResolver } from './end-point-resolver';
import { ResponseCacheService, WITHOUT_EXPIRATION_TIME } from '../responses/response-cache.service';
import { MessageCodes } from '../resources/message-codes';
import { MessageResourceManager } from '../resources/message-resource-manager';
import { LogService, OnlineService, TIMEOUT_CACHE } from '@nts/std/utility';
import { classToPlain, ClassConstructor, plainToClass, plainToClassFromExist } from '@nts/std/serialization';
import { JsonNetDecycle } from '@nts/std/serialization';
import { ServiceInfo } from './service-info';
import { ResponseWithCacheInfo } from '../responses/response-with-cache-info'

export interface CacheOptionsInterface {
    /**
     * se si vuole bypassare il controllo della cache in modalità online
     */
    bypass: boolean,

    /**
     * Scadenza in secondi della cache
     * se si vuole forzare l'expiration time differente da quello impostato dal decoratore,
     * ATTENZIONE non passare null se non si vuole passare, ma usare undefined
     */
    expirationTime?: number,

    /**
     * se si vuole forzare l'utilizzo della cache indipendentemente dall'utilizzo del decoratore @ResponseCachedDecorator
     * se viene abilitato salva la response nella cache in memoria del browser e in locale per l'offline,
     * in aggiunta si può valorizzare cacheExpirationTime per utilizzare quella locale anche per l'online
     */
    force?: boolean,

    /**
     * se si vuole utilizzare un timeout per la chiamata, nel caso se esiste dopo lo scadere del timeout
     * fa fallback sulle versione per l'offline
     */
    enableTimeout?: boolean,

    /**
     * tempo in millisecondi per il timeout
     */
    timeoutMillisecond?: number,

    tenantBarrier?: boolean,

    enterpriseBarrier?: boolean,

    userBarrier?: boolean,

    overrideBarrierValues?: {
        tenantBarrierValue?: number,
        enterpriseBarrierValue?: number,
        userBarrierValue?: number,
    }
}
@Injectable()
export class WebApiServiceAgent {

    rootModelName: string;

    constructor(
        private readonly httpService: HttpClient,
        private readonly endPointResolver: EndPointResolver,
        private readonly responseCache: ResponseCacheService,
        private readonly onlineService: OnlineService,
        // @Optional()
        // @Inject(SERIALIZATION_WEBWORKER_FACTORY) private readonly serializationWebworkerFactory: () => Worker,
        // @Optional()
        // @Inject(RESPONSE_CACHE_SETTINGS_WEBWORKER_FACTORY) private readonly responseCacheWebworkerSettings: ResponseCacheWebWorkerSettings
    ) {

    }

    getHttpService(): HttpClient {
        return this.httpService;
    }

    getServiceInfo(
        useFrameworkService: boolean,
        customControllerName: string = null
    ): ServiceInfo {
        return useFrameworkService ? this.endPointResolver.getWebApiUriForFrameworkService() :
            this.endPointResolver.getWebApiUriForRootDomainModel(customControllerName ?? this.rootModelName);;
    }

    invokePostWithOutBodyWithInstance<TResponse extends ServiceResponse>(
        requestUri: string,
        responseInstance: TResponse,
        useFrameworkService = false,
        customHeaders: HttpHeaders = null,
        customControllerName: string = null,          // example: TipoBollaFatturaReport
        customControllerAddress: string = null,       // example: https://ntscompanydata.ntsinformatica.it/api/TipoBollaFatturaReport
        cacheOptions: CacheOptionsInterface = {
            bypass: false,
            expirationTime: undefined,
            force: false,
        },
        retryRequest: number = 0,
    ): Observable<TResponse> {
        const requestOptions = {
            headers: customHeaders,
        };

        return this.handleRequestWithInstance(
            'POST',
            requestUri,
            null,
            requestOptions,
            responseInstance,
            useFrameworkService,
            customControllerName,
            customControllerAddress,
            cacheOptions,
            retryRequest
        )
    }


    // TODO ????
    invokeGetWithOutQueryWithInstance<TResponse extends ServiceResponse>(
        requestUri: string,
        responseInstance: TResponse,
        useFrameworkService = false,
        customHeaders: HttpHeaders = null,
        customControllerName: string = null,          // example: TipoBollaFatturaReport
        customControllerAddress: string = null,       // example: https://ntscompanydata.ntsinformatica.it/api/TipoBollaFatturaReport,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,
            expirationTime: undefined,
            force: false,
        },
        retryRequest: number = 0,
    ): Observable<TResponse> {

        const serviceInfo = this.getServiceInfo(useFrameworkService, customControllerName);
        const url: string = (customControllerAddress ?? serviceInfo.address) + '/' + requestUri;

        return this.onlineService.isOnline$.pipe(
            take(1),
            switchMap(() => {
                if (this.onlineService.isOnline) {
                    if (!cacheOptions.bypass) {
                        return this.responseCache
                            .check(
                                responseInstance,
                                url,
                                null,
                                this.onlineService.isOnline,
                                cacheOptions.expirationTime,
                                null,
                                cacheOptions.force
                            )
                            .pipe(
                                switchMap((isInCache) => {
                                    if (isInCache) {
                                        return this.responseCache
                                            .get(
                                                responseInstance,
                                                url,
                                                null,
                                                this.onlineService.isOnline,
                                                cacheOptions.expirationTime,
                                                null,
                                                cacheOptions.force
                                            )
                                            .pipe(
                                                switchMap((cachedData) => {
                                                    LogService.debug(
                                                        `Used cache for ${url}`
                                                    );
                                                    return of(this.handleResponse<TResponse>(
                                                        responseInstance,
                                                        cachedData,
                                                        true
                                                    ));
                                                })
                                            );
                                    } else {
                                        const options = {
                                            headers: customHeaders,
                                        };

                                        return this.sendRequest(
                                            "GET",
                                            url,
                                            options,
                                            retryRequest
                                        ).pipe(
                                            switchMap((response) => {
                                                LogService.debug(
                                                    "GET sendRequest",
                                                    response,
                                                    url,
                                                    options
                                                );

                                                const res = this.handleResponse<TResponse>(
                                                    responseInstance,
                                                    response,
                                                    true
                                                );

                                                if (!cacheOptions.bypass) {

                                                  // Se la chiamata è andata a buon fine
                                                  if (res?.errors?.length == 0 && res?.operationSuccedeed == true) {
                                                    this.responseCache.set(
                                                      responseInstance,
                                                      url,
                                                      response,
                                                      null,
                                                      cacheOptions.force
                                                    ).subscribe((res) => {
                                                        // if (this.responseCacheWebworkerSettings?.showLogs) {
                                                        //     LogService.debug('Risultato salvataggio cache: ' + res)
                                                        // }
                                                    });
                                                  }
                                                }

                                                return of(res);
                                            }),
                                            catchError((err) => {
                                                LogService.warn(
                                                    "invokeGetWithOutQueryWithInstance failed",
                                                    err,
                                                    url,
                                                    options
                                                );
                                                return this.handleErrorAsResponseWithInstance<TResponse>(
                                                    err,
                                                    responseInstance
                                                );
                                            })
                                        );
                                    }
                                })
                            );
                    } else {
                        const options = {
                            headers: customHeaders,
                        };

                        return this.sendRequest("GET", url, options).pipe(
                            switchMap((response) => {
                                LogService.debug(
                                    "GET sendRequest",
                                    response,
                                    url,
                                    options
                                );

                                const res = this.handleResponse<TResponse>(
                                  responseInstance,
                                  response,
                                  true
                                )

                                if (!cacheOptions.bypass) {

                                  // Se la chiamata è andata a buon fine
                                  if (res?.errors?.length == 0 && res?.operationSuccedeed == true) {
                                    this.responseCache.set(
                                      responseInstance,
                                      url,
                                      response,
                                      null,
                                      cacheOptions.force
                                    ).subscribe((res) => {
                                        // if (this.responseCacheWebworkerSettings?.showLogs) {
                                        //     LogService.debug('Risultato salvataggio cache: ' + res)
                                        // }
                                    });
                                  }

                                }
                                return of(res);
                            }),
                            catchError((err) => {
                                LogService.warn(
                                    "invokeGetWithOutQueryWithInstance failed",
                                    err,
                                    url,
                                    options
                                );
                                return this.handleErrorAsResponseWithInstance<TResponse>(
                                    err,
                                    responseInstance
                                );
                            })
                        );
                    }
                } else {
                    return this.responseCache
                        .check(
                            responseInstance,
                            url,
                            null,
                            this.onlineService.isOnline,
                            cacheOptions.expirationTime,
                            null,
                            cacheOptions.force
                        )
                        .pipe(
                            switchMap((isInCache) => {
                                if (isInCache) {

                                    return this.responseCache.get(
                                        responseInstance,
                                        url,
                                        null,
                                        this.onlineService.isOnline,
                                        cacheOptions.expirationTime,
                                        null,
                                        cacheOptions.force
                                    ).pipe(switchMap((cachedData) => {
                                        LogService.debug(`Used cache for ${url}`);
                                        return of(this.handleResponse<TResponse>(
                                            responseInstance,
                                            cachedData,
                                            true
                                        ));
                                    }))
                                } else {
                                    const err: any = new Error(
                                        `No cache found for url ${url} with sended data.`
                                    );
                                    LogService.warn(
                                        "invokeGetWithOutQueryWithInstance failed in offline mode",
                                        err,
                                        url,
                                        cacheOptions
                                    );
                                    return this.handleErrorAsResponseWithInstance<TResponse>(
                                        err,
                                        responseInstance
                                    );
                                }
                            })
                        );
                }
            })
        );
    }

    invokeGetWithOutQuery<TResponse extends ServiceResponse>(
        requestUri: string,
        responseType: ClassConstructor<TResponse>,
        useFrameworkService = false,
        customHeaders: HttpHeaders = null,
        customControllerName: string = null,          // example: TipoBollaFatturaReport
        customControllerAddress: string = null,       // example: https://ntscompanydata.ntsinformatica.it/api/TipoBollaFatturaReport,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,
            expirationTime: undefined,
            force: false,
        },
        retryRequest: number = 0,
    ): Observable<TResponse> {

        const requestOptions = {
            headers: customHeaders,
        };

        return this.handleRequest(
            'GET',
            requestUri,
            undefined,
            requestOptions,
            responseType,
            useFrameworkService,
            customControllerName,
            customControllerAddress,
            cacheOptions,
            retryRequest
        )
    }

    invokePostUploadFile<TResponse extends ServiceResponse>(
        requestUri: string,
        formData: FormData,
        responseInstance: TResponse,
        useFrameworkService = false,
        customHeaders: HttpHeaders = null,
        customControllerName: string = null,          // example: TipoBollaFatturaReport
        customControllerAddress: string = null,       // example: https://ntscompanydata.ntsinformatica.it/api/TipoBollaFatturaReport
        retryRequest: number = 0,
    ): Observable<TResponse> {

        const serviceInfo = this.getServiceInfo(useFrameworkService, customControllerName);
        const url: string = (customControllerAddress ?? serviceInfo.address) + '/' + requestUri;

        const options = {
            body: formData,
            observe: 'events',
            reportProgress: true,
            headers: customHeaders
        };

        return this.sendRequest('POST', url, options, retryRequest).pipe(
            map(response => {
                // LogService.debug('POST sendRequest', response, url, options);
                return response;
            }),
            catchError(err => {
                LogService.warn('invokePostUploadFile failed', err, url, options);
                return this.handleErrorAsResponseWithInstance<TResponse>(err, responseInstance);
            })
        );
    }

    invokePostUploadFileWithInstance<TResponse extends ServiceResponse>(
        requestUri: string,
        formData: FormData,
        responseInstance: TResponse,
        useFrameworkService = false,
        customHeaders: HttpHeaders = null,
        customControllerName: string = null,          // example: TipoBollaFatturaReport
        customControllerAddress: string = null,        // example: https://ntscompanydata.ntsinformatica.it/api/TipoBollaFatturaReport
        retryRequest: number = 0,
    ): Observable<TResponse> {

        const serviceInfo = this.getServiceInfo(useFrameworkService, customControllerName);
        const url: string = (customControllerAddress ?? serviceInfo.address) + '/' + requestUri;

        const options = {
            body: formData,
            observe: 'events',
            reportProgress: true,
            headers: customHeaders
        };

        return this.sendRequest('POST', url, options, retryRequest).pipe(
            switchMap((httpEvent: any) => {
                if (httpEvent) {
                    if (httpEvent.type === HttpEventType.UploadProgress) {
                        LogService.debug(`Upload in progress: ${Math.round(100 * httpEvent.loaded / httpEvent.total)}`)
                        return of(httpEvent);
                    } else if (httpEvent.type === HttpEventType.Response) {
                        LogService.debug(`Upload complete:`, httpEvent.body)
                        return of(this.handleResponse<TResponse>(responseInstance, httpEvent.body, true));
                    }
                }
                return of(httpEvent);
            }),
            catchError(err => {
                LogService.warn('invokePostUploadFileWithInstance failed', err, url, options);
                return this.handleErrorAsResponseWithInstance<TResponse>(err, responseInstance);
            })
        );
    }

    invokePostDownloadFile<TRequest extends ServiceRequest, TResponse extends Blob>(
        requestUri: string,
        requestContent: TRequest,
        useFrameworkService = false,
        customHeaders: HttpHeaders = null,
        customControllerName: string = null,          // example: TipoBollaFatturaReport
        customControllerAddress: string = null,       // example: https://ntscompanydata.ntsinformatica.it/api/TipoBollaFatturaReport
        retryRequest: number = 0,
    ): Observable<Blob> {

        const serviceInfo = this.getServiceInfo(useFrameworkService, customControllerName);
        const url: string = (customControllerAddress ?? serviceInfo.address) + '/' + requestUri;

        const requestPlain = classToPlain(requestContent, { strategy: 'excludeAll' });
        const cycledRequestPlain = JsonNetDecycle.decycle(requestPlain);
        const options = {
            body: cycledRequestPlain,
            observe: 'events',
            responseType: 'blob',
            headers: customHeaders
        };

        return this.sendRequest('POST', url, options, retryRequest).pipe(
            map(response => {
                // LogService.debug('POST sendRequest', response, url, options);
                return response;
            }),
            catchError(err => {
                LogService.warn('invokePostDownloadFile failed', err, url, options);
                return this.handleErrorAsResponse(<TResponse>(err));
            })
        );
    }

    invokePostDownloadFileWithOutBody<TResponse extends Blob>(
        requestUri: string,
        useFrameworkService = false,
        customHeaders: HttpHeaders = null,
        customControllerName: string = null,          // example: TipoBollaFatturaReport
        customControllerAddress: string = null,       // example: https://ntscompanydata.ntsinformatica.it/api/TipoBollaFatturaReport
        retryRequest: number = 0,
    ): Observable<Blob> {

        const serviceInfo = this.getServiceInfo(useFrameworkService, customControllerName);
        const url: string = (customControllerAddress ?? serviceInfo.address) + '/' + requestUri;

        const options = {
            observe: 'events',
            responseType: 'blob',
            headers: customHeaders
        };

        return this.sendRequest('POST', url, options, retryRequest).pipe(
            map(response => {
                // LogService.debug('POST sendRequest', response, url, options);
                return response;
            }),
            catchError(err => {
                LogService.warn('invokePostDownloadFileWithOutBody failed', err, url, options);
                return this.handleErrorAsResponse(<TResponse>(err));
            })
        );
    }

    invokePostWithResponseWithInstance<TRequest extends ServiceRequest, TResponse extends ServiceResponse>(
        requestUri: string,
        requestContent: TRequest,
        responseInstance: TResponse,
        useFrameworkService = false,
        customHeaders: HttpHeaders = null,
        customControllerName: string = null,          // example: TipoBollaFatturaReport
        customControllerAddress: string = null,       // example: https://ntscompanydata.ntsinformatica.it/api/TipoBollaFatturaReport
        cacheOptions: CacheOptionsInterface = {
            bypass: false,
            expirationTime: undefined,
            force: false,
        },
        retryRequest: number = 0,
    ): Observable<TResponse> {

        const requestPlain = classToPlain(requestContent, { strategy: 'excludeAll' });
        const cycledRequestPlain = JsonNetDecycle.decycle(requestPlain);

        const requestOptions = {
            body: cycledRequestPlain,
            headers: customHeaders
        };

        return this.handleRequestWithInstance(
            'POST',
            requestUri,
            cycledRequestPlain,
            requestOptions,
            responseInstance,
            useFrameworkService,
            customControllerName,
            customControllerAddress,
            cacheOptions,
            retryRequest
        )
    }

    invokePostWithResponseWithInstanceWithCacheInfo<TRequest extends ServiceRequest, TResponse extends ServiceResponse>(
        requestUri: string,
        requestContent: TRequest,
        responseInstance: TResponse,
        useFrameworkService = false,
        customHeaders: HttpHeaders = null,
        customControllerName: string = null,          // example: TipoBollaFatturaReport
        customControllerAddress: string = null,       // example: https://ntscompanydata.ntsinformatica.it/api/TipoBollaFatturaReport
        cacheOptions: CacheOptionsInterface = {
            bypass: false,
            expirationTime: undefined,
            force: false,
        },
        retryRequest: number = 0,
    ): Observable<ResponseWithCacheInfo<TResponse>> {

        const requestPlain = classToPlain(requestContent, { strategy: 'excludeAll' });
        const cycledRequestPlain = JsonNetDecycle.decycle(requestPlain);

        const requestOptions = {
            body: cycledRequestPlain,
            headers: customHeaders
        };

        return this.handleRequestWithInstanceWithCacheInfo(
            'POST',
            requestUri,
            cycledRequestPlain,
            requestOptions,
            responseInstance,
            useFrameworkService,
            customControllerName,
            customControllerAddress,
            cacheOptions,
            retryRequest
        )
    }

    invokePostWithOutResponse<TRequest extends ServiceRequest>(
        requestUri: string,
        requestContent: TRequest,
        useFrameworkService = false,
        customHeaders: HttpHeaders = null,
        customControllerName: string = null,          // example: TipoBollaFatturaReport
        customControllerAddress: string = null,       // example: https://ntscompanydata.ntsinformatica.it/api/TipoBollaFatturaReport,
        retryRequest: number = 0,
    ): Observable<ServiceResponse> {

        const serviceInfo = this.getServiceInfo(useFrameworkService, customControllerName);
        const url: string = (customControllerAddress ?? serviceInfo.address) + '/' + requestUri;

        const requestPlain = classToPlain(requestContent, { strategy: 'excludeAll' });
        const cycledRequestPlain = JsonNetDecycle.decycle(requestPlain);

        const options = {
            body: cycledRequestPlain,
            headers: customHeaders
        };

        return this.sendRequest('POST', url, options, retryRequest).pipe(
            map(response => {
                // LogService.debug('POST sendRequest', response, url, options);
                return new ServiceResponse();
            }),
            catchError(err => {
                LogService.warn('invokePostWithOutResponse failed', err, url, options);
                return this.handleErrorAsResponse<ServiceResponse>(err);
            })
        );

    }

    invokePostWithResponse<TRequest extends ServiceRequest, TResponse extends ServiceResponse>(
        requestUri: string,
        requestContent: TRequest,
        responseType: ClassConstructor<TResponse>,
        useFrameworkService = false,
        customHeaders: HttpHeaders = null,
        customControllerName: string = null,            // example: TipoBollaFatturaReport
        customControllerAddress: string = null,         // example: https://ntscompanydata.ntsinformatica.it/api/TipoBollaFatturaReport
        cacheOptions: CacheOptionsInterface = {
            bypass: false,
            expirationTime: undefined,
            force: false,
        },
        retryRequest: number = 0,
    ): Observable<TResponse> {

        const requestPlain = classToPlain(requestContent, { strategy: 'excludeAll' });
        const cycledRequestPlain = JsonNetDecycle.decycle(requestPlain);

        const requestOptions = {
            body: cycledRequestPlain,
            headers: customHeaders
        };

        return this.handleRequest(
            'POST',
            requestUri,
            cycledRequestPlain,
            requestOptions,
            responseType,
            useFrameworkService,
            customControllerName,
            customControllerAddress,
            cacheOptions,
            retryRequest
        )
    }

    private handleRequestWithInstanceWithCacheInfo<TResponse extends ServiceResponse>(
        method: string,
        requestUri: string,
        cycledRequestPlain: Record<string, any>,
        requestOptions: any,
        responseInstance: TResponse,
        useFrameworkService: boolean = false,
        customControllerName: string = null,
        customControllerAddress: string = null,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,
            expirationTime: undefined,
            force: false,
        },
        retryRequest: number = 0,
    ): Observable<ResponseWithCacheInfo<TResponse>> {
        const serviceInfo = this.getServiceInfo(useFrameworkService, customControllerName);
        const url: string = (customControllerAddress ?? serviceInfo.address) + '/' + requestUri;

        // Sono online
        if (this.onlineService.isOnline) {

            // Se non ho attivo il bypass della cache
            if (!cacheOptions.bypass) {

                // Verifico se esiste una cache salvata per questa richiesta
                return this.responseCache.check(
                    responseInstance,
                    url,
                    cycledRequestPlain ? JSON.stringify(cycledRequestPlain) : null,
                    this.onlineService.isOnline,
                    WITHOUT_EXPIRATION_TIME,
                    null,
                    true,
                    cacheOptions.tenantBarrier,
                    cacheOptions.enterpriseBarrier,
                    cacheOptions.userBarrier,
                    cacheOptions.overrideBarrierValues
                ).pipe(
                    switchMap((hasCache) => {

                        // Verifico se esiste una cache salvata e non scaduta per questa richiesta
                        return this.responseCache.check(
                            responseInstance,
                            url,
                            cycledRequestPlain ? JSON.stringify(cycledRequestPlain) : null,
                            this.onlineService.isOnline,
                            cacheOptions.expirationTime,
                            null,
                            cacheOptions.force,
                            cacheOptions.tenantBarrier,
                            cacheOptions.enterpriseBarrier,
                            cacheOptions.userBarrier,
                            cacheOptions.overrideBarrierValues
                        ).pipe(
                            switchMap((isCacheNotExpired) => {

                                // Se la cache salvata non è scaduta
                                if (isCacheNotExpired) {

                                    // Ritorno la cache non scaduta invece di effettuare la chiamata
                                    return this.responseCache.get(
                                        responseInstance,
                                        url,
                                        cycledRequestPlain ? JSON.stringify(cycledRequestPlain) : null,
                                        this.onlineService.isOnline,
                                        cacheOptions.expirationTime,
                                        null,
                                        cacheOptions.force,
                                        cacheOptions.tenantBarrier,
                                        cacheOptions.enterpriseBarrier,
                                        cacheOptions.userBarrier,
                                        cacheOptions.overrideBarrierValues
                                    ).pipe(
                                        switchMap((cachedData) => {

                                          // Recupero la response
                                          const resFromCache = this.handleResponse<TResponse>(
                                            responseInstance, cachedData, true
                                          );

                                          // Se la chiamata non è andata a buon fine
                                          if (resFromCache?.errors?.length > 0 || resFromCache?.operationSuccedeed == false) {

                                            const timeoutCacheMs = 0

                                            // Effettuo la chiamata, passando anche i parametri per l'eventuale risposta per il timeout
                                            return this.sendRequest(
                                                method,
                                                url,
                                                requestOptions,
                                                retryRequest,
                                                timeoutCacheMs,
                                            ).pipe(
                                                switchMap((response) => {
                                                    LogService.debug(`${method} ${url}`, response, url, requestOptions , cycledRequestPlain);

                                                    const res = this.handleResponse<TResponse>(
                                                      responseInstance, response, true
                                                    );

                                                    // Se la chiamata è andata a buon fine
                                                    if (res?.errors?.length == 0 && res?.operationSuccedeed == true) {
                                                      // Aggiorno la cache per l'offline/timeout
                                                      this.responseCache.set(
                                                        responseInstance,
                                                        url,
                                                        response,
                                                        cycledRequestPlain ? JSON.stringify(cycledRequestPlain) : null,
                                                        cacheOptions.force,
                                                        cacheOptions.tenantBarrier,
                                                        cacheOptions.enterpriseBarrier,
                                                        cacheOptions.userBarrier,
                                                        cacheOptions.overrideBarrierValues
                                                      ).subscribe((res) => {
                                                        // if (this.responseCacheWebworkerSettings?.showLogs) {
                                                        //     LogService.debug('Risultato salvataggio cache: ' + res)
                                                        // }
                                                      });
                                                    }

                                                    return of(res).pipe(
                                                        switchMap(async (responseData) => {
                                                            const res = new ResponseWithCacheInfo<TResponse>();
                                                            res.response = responseData;

                                                            const currentDate = new Date();
                                                            const timestamp = Math.round(currentDate.getTime() / 1000);

                                                            res.cache = {timestamp};

                                                            return res;
                                                        })
                                                    )

                                                }),
                                                catchError((err) => {
                                                    LogService.warn(
                                                        `${method} ${url} failed`,
                                                        err,
                                                        url,
                                                        requestOptions
                                                    );
                                                    return this.handleErrorAsResponse<TResponse>(err).pipe(map((r) => {
                                                        const res = new ResponseWithCacheInfo<TResponse>();
                                                        const currentDate = new Date();
                                                        const timestamp = Math.round(currentDate.getTime() / 1000);
                                                        res.response = r;
                                                        res.cache = {
                                                            timestamp
                                                        }
                                                        return res;
                                                    }));
                                                })
                                            );

                                          } else {

                                            LogService.debug(
                                              `Utilizzo la cache non scaduta per la chiamata ${method} ${url}`, cycledRequestPlain
                                            );
                                            return of(this.handleResponse<TResponse>(
                                                responseInstance, cachedData, true
                                            )).pipe(
                                                switchMap(async (responseData) => {
                                                    const res = new ResponseWithCacheInfo<TResponse>();
                                                    res.response = responseData;

                                                    const timestamp = await this.responseCache.getTimestampFromOffLineCacheForSpecificRequest(
                                                        url,
                                                        cycledRequestPlain ? JSON.stringify(cycledRequestPlain) : null,
                                                        cacheOptions.tenantBarrier,
                                                        cacheOptions.enterpriseBarrier,
                                                        cacheOptions.userBarrier,
                                                        cacheOptions.overrideBarrierValues
                                                    )

                                                    res.cache = {timestamp};

                                                    return res;
                                                })
                                            )

                                          }

                                        })
                                    );

                                } else {

                                    // Se la cache salvata è scaduta
                                    const timeoutCacheMs = hasCache && cacheOptions.enableTimeout ?
                                        (cacheOptions.timeoutMillisecond ?? TIMEOUT_CACHE) :
                                        0

                                    // Effettuo la chimata, passando anche i parametri per l'eventuale risposta per il timeout
                                    return this.sendRequest(
                                        method,
                                        url,
                                        requestOptions,
                                        retryRequest,
                                        timeoutCacheMs,
                                        this.responseCache.get(
                                            responseInstance,
                                            url,
                                            cycledRequestPlain ? JSON.stringify(cycledRequestPlain) : null,
                                            this.onlineService.isOnline,
                                            WITHOUT_EXPIRATION_TIME,
                                            null,
                                            true,
                                            cacheOptions.tenantBarrier,
                                            cacheOptions.enterpriseBarrier,
                                            cacheOptions.userBarrier,
                                            cacheOptions.overrideBarrierValues
                                        ).pipe(tap((res) => {
                                            LogService.debug(`Uso la cache a causa del timeout di ${timeoutCacheMs} per la chiamata ${method} ${url}`, cycledRequestPlain);
                                        }))
                                    ).pipe(
                                        switchMap((response) => {
                                            LogService.debug(`${method} ${url}`, response, url, requestOptions , cycledRequestPlain);

                                            const res = this.handleResponse<TResponse>(
                                              responseInstance, response, true
                                            );

                                            // Se la chiamata è andata a buon fine
                                            if (res?.errors?.length == 0 && res?.operationSuccedeed == true) {
                                                // Aggiorno la cache per l'offline/timeout
                                                this.responseCache.set(
                                                  responseInstance,
                                                  url,
                                                  response,
                                                  cycledRequestPlain ? JSON.stringify(cycledRequestPlain) : null,
                                                  cacheOptions.force,
                                                  cacheOptions.tenantBarrier,
                                                  cacheOptions.enterpriseBarrier,
                                                  cacheOptions.userBarrier,
                                                  cacheOptions.overrideBarrierValues
                                              ).subscribe((res) => {
                                                  // if (this.responseCacheWebworkerSettings?.showLogs) {
                                                  //     LogService.debug('Risultato salvataggio cache: ' + res)
                                                  // }
                                              });
                                            }

                                            return of(res).pipe(
                                                switchMap(async (responseData) => {
                                                    const res = new ResponseWithCacheInfo<TResponse>();
                                                    res.response = responseData;

                                                    const currentDate = new Date();
                                                    const timestamp = Math.round(currentDate.getTime() / 1000);

                                                    res.cache = {timestamp};

                                                    return res;
                                                })
                                            )
                                        }),
                                        catchError((err) => {
                                            LogService.warn(
                                                `${method} ${url} failed`,
                                                err,
                                                url,
                                                requestOptions
                                            );
                                            return this.handleErrorAsResponse<TResponse>(err).pipe(map((r) => {
                                                const res = new ResponseWithCacheInfo<TResponse>();
                                                const currentDate = new Date();
                                                const timestamp = Math.round(currentDate.getTime() / 1000);
                                                res.response = r;
                                                res.cache = {
                                                    timestamp
                                                }
                                                return res;
                                            }));
                                        })
                                    );
                                }
                              })
                            );
                        })
                    )
            } else {

                // Se ho attivo il bypass della cache
                return this.sendRequest(method, url, requestOptions, retryRequest).pipe(
                    switchMap((response) => {
                        LogService.debug(`${method} ${url}`, response, url, requestOptions);

                        const res = this.handleResponse<TResponse>(
                          responseInstance, response, true
                        );

                        // Se la chiamata è andata a buon fine
                        if (res?.errors?.length == 0 && res?.operationSuccedeed == true) {
                          // Aggiorno la cache per l'offline/timeout
                          this.responseCache.set(
                            responseInstance,
                            url,
                            response,
                            cycledRequestPlain ? JSON.stringify(cycledRequestPlain) : null,
                            cacheOptions.force,
                            cacheOptions.tenantBarrier,
                            cacheOptions.enterpriseBarrier,
                            cacheOptions.userBarrier,
                            cacheOptions.overrideBarrierValues
                          ).subscribe((res) => {
                              // if (this.responseCacheWebworkerSettings?.showLogs) {
                              //     LogService.debug('Risultato salvataggio cache: ' + res)
                              // }
                          });
                        }

                        return of(res).pipe(
                            switchMap(async (responseData) => {
                                const res = new ResponseWithCacheInfo<TResponse>();
                                res.response = responseData;

                                const currentDate = new Date();
                                const timestamp = Math.round(currentDate.getTime() / 1000);

                                res.cache = {timestamp};

                                return res;
                            })
                        )
                    }),
                    catchError((err) => {
                        LogService.warn(
                            `${method} ${url} failed`,
                            err,
                            url,
                            requestOptions
                        );
                        return this.handleErrorAsResponse<TResponse>(err).pipe(map((r) => {
                            const res = new ResponseWithCacheInfo<TResponse>();
                            const currentDate = new Date();
                            const timestamp = Math.round(currentDate.getTime() / 1000);
                            res.response = r;
                            res.cache = {
                                timestamp
                            }
                            return res;
                        }));
                    })
                );
            }
        } else {
            // Sono offline
            return this.responseCache.check(
                responseInstance,
                url,
                cycledRequestPlain ? JSON.stringify(cycledRequestPlain) : null,
                this.onlineService.isOnline,
                cacheOptions.expirationTime,
                null,
                cacheOptions.force,
                cacheOptions.tenantBarrier,
                cacheOptions.enterpriseBarrier,
                cacheOptions.userBarrier,
                cacheOptions.overrideBarrierValues
            ).pipe(
                switchMap((isInCache) => {
                    // Ho la cache per l'offline
                    if (isInCache) {
                        return this.responseCache.get(
                            responseInstance,
                            url,
                            cycledRequestPlain ? JSON.stringify(cycledRequestPlain) : null,
                            this.onlineService.isOnline,
                            cacheOptions.expirationTime,
                            null,
                            cacheOptions.force,
                            cacheOptions.tenantBarrier,
                            cacheOptions.enterpriseBarrier,
                            cacheOptions.userBarrier,
                            cacheOptions.overrideBarrierValues
                        ).pipe(switchMap((cachedData) => {
                            LogService.debug(`Used cache for ${url}`);

                            return of(this.handleResponse<TResponse>(
                                responseInstance, cachedData, true
                            )).pipe(
                                switchMap(async (responseData) => {
                                    const res = new ResponseWithCacheInfo<TResponse>();
                                    res.response = responseData;

                                    const timestamp = await this.responseCache.getTimestampFromOffLineCacheForSpecificRequest(
                                        url,
                                        cycledRequestPlain ? JSON.stringify(cycledRequestPlain) : null,
                                        cacheOptions.tenantBarrier,
                                        cacheOptions.enterpriseBarrier,
                                        cacheOptions.userBarrier,
                                        cacheOptions.overrideBarrierValues
                                    )

                                    res.cache = {timestamp};

                                    return res;
                                })
                            )
                        }))

                    } else {

                        // Non ho la cache per l'offline
                        const err: any = new Error(
                            `No cache found for ${method} ${url} with sended data.`
                        );
                        LogService.warn(
                            `${method} ${url} failed in offline mode`,
                            err,
                            url,
                            cacheOptions
                        );
                        return this.handleErrorAsResponse<TResponse>(err).pipe(map((r) => {
                            const res = new ResponseWithCacheInfo<TResponse>();
                            const currentDate = new Date();
                            const timestamp = Math.round(currentDate.getTime() / 1000);
                            res.response = r;
                            res.cache = {
                                timestamp
                            }
                            return res;
                        }));
                    }
                })
            );
        }
    }

    private handleRequestWithInstance<TResponse extends ServiceResponse>(
        method: string,
        requestUri: string,
        cycledRequestPlain: Record<string, any>,
        requestOptions: any,
        responseInstance: TResponse,
        useFrameworkService: boolean = false,
        customControllerName: string = null,
        customControllerAddress: string = null,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,
            expirationTime: undefined,
            force: false,
        },
        retryRequest: number = 0,
    ): Observable<TResponse> {
        const serviceInfo = this.getServiceInfo(useFrameworkService, customControllerName);
        const url: string = (customControllerAddress ?? serviceInfo.address) + '/' + requestUri;

        // Sono online
        if (this.onlineService.isOnline) {

            // Se non ho attivo il bypass della cache
            if (!cacheOptions.bypass) {

                // Verifico se esiste una cache salvata per questa richiesta
                return this.responseCache.check(
                    responseInstance,
                    url,
                    cycledRequestPlain ? JSON.stringify(cycledRequestPlain) : null,
                    this.onlineService.isOnline,
                    WITHOUT_EXPIRATION_TIME,
                    null,
                    true,
                    cacheOptions.tenantBarrier,
                    cacheOptions.enterpriseBarrier,
                    cacheOptions.userBarrier,
                    cacheOptions.overrideBarrierValues
                ).pipe(
                    switchMap((hasCache) => {

                        // Verifico se esiste una cache salvata e non scaduta per questa richiesta
                        return this.responseCache.check(
                            responseInstance,
                            url,
                            cycledRequestPlain ? JSON.stringify(cycledRequestPlain) : null,
                            this.onlineService.isOnline,
                            cacheOptions.expirationTime,
                            null,
                            cacheOptions.force,
                            cacheOptions.tenantBarrier,
                            cacheOptions.enterpriseBarrier,
                            cacheOptions.userBarrier,
                            cacheOptions.overrideBarrierValues
                        ).pipe(
                            switchMap((isCacheNotExpired) => {

                                // Se la cache salvata non è scaduta
                                if (isCacheNotExpired) {

                                    // Ritorno la cache non scaduta invece di effettuare la chiamata
                                    return this.responseCache.get(
                                        responseInstance,
                                        url,
                                        cycledRequestPlain ? JSON.stringify(cycledRequestPlain) : null,
                                        this.onlineService.isOnline,
                                        cacheOptions.expirationTime,
                                        null,
                                        cacheOptions.force,
                                        cacheOptions.tenantBarrier,
                                        cacheOptions.enterpriseBarrier,
                                        cacheOptions.userBarrier,
                                        cacheOptions.overrideBarrierValues
                                    ).pipe(
                                        switchMap((cachedData) => {

                                          // Recupero la response
                                          const resFromCache = this.handleResponse<TResponse>(
                                            responseInstance, cachedData, true
                                          );

                                          // Se la chiamata non è andata a buon fine
                                          if (resFromCache?.errors?.length > 0 || resFromCache?.operationSuccedeed == false) {

                                            const timeoutCacheMs = 0;

                                            // Effettuo la chiamata
                                            return this.sendRequest(
                                                method,
                                                url,
                                                requestOptions,
                                                retryRequest,
                                                timeoutCacheMs,
                                            ).pipe(
                                                switchMap((response) => {
                                                    LogService.debug(`${method} ${url}`, response, url, requestOptions , cycledRequestPlain);

                                                    // Recupero la response
                                                    const res = this.handleResponse<TResponse>(
                                                      responseInstance, response, true
                                                    );

                                                    // Se la chiamata è andata a buon fine
                                                    if (res?.errors?.length == 0 && res?.operationSuccedeed == true) {
                                                      // Aggiorno la cache per l'offline/timeout
                                                      this.responseCache.set(
                                                        responseInstance,
                                                        url,
                                                        response,
                                                        cycledRequestPlain ? JSON.stringify(cycledRequestPlain) : null,
                                                        cacheOptions.force,
                                                        cacheOptions.tenantBarrier,
                                                        cacheOptions.enterpriseBarrier,
                                                        cacheOptions.userBarrier,
                                                        cacheOptions.overrideBarrierValues
                                                      ).subscribe((res) => {
                                                        // if (this.responseCacheWebworkerSettings?.showLogs) {
                                                        //     LogService.debug('Risultato salvataggio cache: ' + res)
                                                        // }
                                                      });
                                                    }

                                                    return of(res);
                                                }),
                                                catchError((err) => {
                                                    LogService.warn(
                                                        `${method} ${url} failed`,
                                                        err,
                                                        url,
                                                        requestOptions
                                                    );
                                                    return this.handleErrorAsResponse<TResponse>(err);
                                                })
                                            );

                                          } else {
                                            LogService.debug(
                                              `Utilizzo la cache non scaduta per la chiamata ${method} ${url}`, cycledRequestPlain
                                            );
                                            return of(resFromCache);
                                          }
                                        })
                                    );

                                } else {

                                    // Se la cache salvata è scaduta
                                    const timeoutCacheMs = hasCache && cacheOptions.enableTimeout ?
                                        (cacheOptions.timeoutMillisecond ?? TIMEOUT_CACHE) :
                                        0

                                    // Effettuo la chimata, passando anche i parametri per l'eventuale risposta per il timeout
                                    return this.sendRequest(
                                        method,
                                        url,
                                        requestOptions,
                                        retryRequest,
                                        timeoutCacheMs,
                                        this.responseCache.get(
                                            responseInstance,
                                            url,
                                            cycledRequestPlain ? JSON.stringify(cycledRequestPlain) : null,
                                            this.onlineService.isOnline,
                                            WITHOUT_EXPIRATION_TIME,
                                            null,
                                            true,
                                            cacheOptions.tenantBarrier,
                                            cacheOptions.enterpriseBarrier,
                                            cacheOptions.userBarrier,
                                            cacheOptions.overrideBarrierValues
                                        ).pipe(tap((res) => {
                                            LogService.debug(`Uso la cache a causa del timeout di ${timeoutCacheMs} per la chiamata ${method} ${url}`, cycledRequestPlain);
                                        }))
                                    ).pipe(
                                        switchMap((response) => {
                                            LogService.debug(`${method} ${url}`, response, url, requestOptions , cycledRequestPlain);

                                            const res = this.handleResponse<TResponse>(
                                              responseInstance, response, true
                                            )

                                            // Se non devo bypassare la cache
                                            if (!cacheOptions.bypass) {

                                                // Se la chiamata è andata a buon fine
                                                if (res?.errors?.length == 0 && res?.operationSuccedeed == true) {

                                                  // Aggiorno la cache per l'offline/timeout
                                                  this.responseCache.set(
                                                      responseInstance,
                                                      url,
                                                      response,
                                                      cycledRequestPlain ? JSON.stringify(cycledRequestPlain) : null,
                                                      cacheOptions.force,
                                                      cacheOptions.tenantBarrier,
                                                      cacheOptions.enterpriseBarrier,
                                                      cacheOptions.userBarrier,
                                                      cacheOptions.overrideBarrierValues
                                                  ).subscribe((res) => {
                                                      // if (this.responseCacheWebworkerSettings?.showLogs) {
                                                      //     LogService.debug('Risultato salvataggio cache: ' + res)
                                                      // }
                                                  });

                                                }
                                            }
                                            return of(res);
                                        }),
                                        catchError((err) => {
                                            LogService.warn(
                                                `${method} ${url} failed`,
                                                err,
                                                url,
                                                requestOptions
                                            );
                                            return this.handleErrorAsResponse<TResponse>(err);
                                        })
                                    );
                                }
                              })
                            );
                        })
                    )
            } else {

                // Se ho attivo il bypass della cache
                return this.sendRequest(method, url, requestOptions, retryRequest).pipe(
                    switchMap((response) => {
                        LogService.debug(`${method} ${url}`, response, url, requestOptions);
                        return of(this.handleResponse<TResponse>(
                            responseInstance, response, true
                        ));
                    }),
                    catchError((err) => {
                        LogService.warn(
                            `${method} ${url} failed`,
                            err,
                            url,
                            requestOptions
                        );
                        return this.handleErrorAsResponse<TResponse>(err);
                    })
                );
            }
        } else {
            // Sono offline
            return this.responseCache.check(
                responseInstance,
                url,
                cycledRequestPlain ? JSON.stringify(cycledRequestPlain) : null,
                this.onlineService.isOnline,
                cacheOptions.expirationTime,
                null,
                cacheOptions.force,
                cacheOptions.tenantBarrier,
                cacheOptions.enterpriseBarrier,
                cacheOptions.userBarrier,
                cacheOptions.overrideBarrierValues
            ).pipe(
                switchMap((isInCache) => {
                    // Ho la cache per l'offline
                    if (isInCache) {
                        return this.responseCache.get(
                            responseInstance,
                            url,
                            cycledRequestPlain ? JSON.stringify(cycledRequestPlain) : null,
                            this.onlineService.isOnline,
                            cacheOptions.expirationTime,
                            null,
                            cacheOptions.force,
                            cacheOptions.tenantBarrier,
                            cacheOptions.enterpriseBarrier,
                            cacheOptions.userBarrier,
                            cacheOptions.overrideBarrierValues
                        ).pipe(switchMap((cachedData) => {
                            LogService.debug(`Used cache for ${url}`);
                            return of(this.handleResponse<TResponse>(
                                responseInstance, cachedData, true
                            ));
                        }))

                    } else {

                        // Non ho la cache per l'offline
                        const err: any = new Error(
                            `No cache found for ${method} ${url} with sended data.`
                        );
                        LogService.warn(
                            `${method} ${url} failed in offline mode`,
                            err,
                            url,
                            cacheOptions
                        );
                        return this.handleErrorAsResponse<TResponse>(err);
                    }
                })
            );
        }
    }

    private handleRequest<TResponse extends ServiceResponse>(
        method: string,
        requestUri: string,
        cycledRequestPlain: Record<string, any>,
        requestOptions: any,
        responseType: ClassConstructor<TResponse>,
        useFrameworkService: boolean = false,
        customControllerName: string = null,
        customControllerAddress: string = null,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,
            expirationTime: undefined,
            force: false,
        },
        retryRequest: number = 0,
    ) {
        const serviceInfo = this.getServiceInfo(useFrameworkService, customControllerName);
        const url: string = (customControllerAddress ?? serviceInfo.address) + '/' + requestUri;

        // Sono online
        if (this.onlineService.isOnline) {

            // Se non ho attivo il bypass della cache
            if (!cacheOptions.bypass) {

                // Verifico se esiste una cache salvata per questa richiesta
                return this.responseCache.check(
                    responseType,
                    url,
                    cycledRequestPlain ? JSON.stringify(cycledRequestPlain) : null,
                    this.onlineService.isOnline,
                    WITHOUT_EXPIRATION_TIME,
                    null,
                    true,
                    cacheOptions.tenantBarrier,
                    cacheOptions.enterpriseBarrier,
                    cacheOptions.userBarrier,
                    cacheOptions.overrideBarrierValues
                ).pipe(
                    switchMap((hasCache) => {

                        // Verifico se esiste una cache salvata e non scaduta per questa richiesta
                        return this.responseCache.check(
                            responseType,
                            url,
                            cycledRequestPlain ? JSON.stringify(cycledRequestPlain) : null,
                            this.onlineService.isOnline,
                            cacheOptions.expirationTime,
                            null,
                            cacheOptions.force,
                            cacheOptions.tenantBarrier,
                            cacheOptions.enterpriseBarrier,
                            cacheOptions.userBarrier,
                            cacheOptions.overrideBarrierValues
                        ).pipe(
                            switchMap((isCacheNotExpired) => {

                                // Se la cache salvata non è scaduta
                                if (isCacheNotExpired) {

                                    // Ritorno la cache non scaduta invece di effettuare la chiamata
                                    return this.responseCache.get(
                                        responseType,
                                        url,
                                        cycledRequestPlain ? JSON.stringify(cycledRequestPlain) : null,
                                        this.onlineService.isOnline,
                                        cacheOptions.expirationTime,
                                        null,
                                        cacheOptions.force,
                                        cacheOptions.tenantBarrier,
                                        cacheOptions.enterpriseBarrier,
                                        cacheOptions.userBarrier,
                                        cacheOptions.overrideBarrierValues
                                    ).pipe(
                                        switchMap((cachedData) => {

                                            // Recupero la response
                                            const resFromCache = this.handleResponse<TResponse>(
                                              responseType, cachedData, false
                                            );

                                            // Se la chiamata non è andata a buon fine
                                            if (resFromCache?.errors?.length > 0 || resFromCache?.operationSuccedeed == false) {

                                              const timeoutCacheMs = 0

                                              // Effettuo la chimata
                                              return this.sendRequest(
                                                method,
                                                url,
                                                requestOptions,
                                                retryRequest,
                                                timeoutCacheMs,

                                              ).pipe(
                                                switchMap((response) => {
                                                  LogService.debug(`${method} ${url}`, response, url, requestOptions);

                                                  const res = this.handleResponse<TResponse>(
                                                    responseType, response, false
                                                  )

                                                  // Se la chiamata è andata a buon fine
                                                  if (res?.errors?.length == 0 && res?.operationSuccedeed == true) {
                                                    // Aggiorno la cache per l'offline/timeout
                                                    this.responseCache.set(
                                                      responseType,
                                                      url,
                                                      response,
                                                      cycledRequestPlain ? JSON.stringify(cycledRequestPlain) : null,
                                                      cacheOptions.force,
                                                      cacheOptions.tenantBarrier,
                                                      cacheOptions.enterpriseBarrier,
                                                      cacheOptions.userBarrier,
                                                      cacheOptions.overrideBarrierValues
                                                    ).subscribe((res) => {
                                                        // if (this.responseCacheWebworkerSettings?.showLogs) {
                                                        //     LogService.debug('Risultato salvataggio cache: ' + res, cycledRequestPlain)
                                                        // }
                                                    });
                                                  }

                                                  return of(res);
                                                }),
                                                catchError((err) => {
                                                    LogService.warn(
                                                        `${method} ${url} failed`,
                                                        err,
                                                        url,
                                                        requestOptions
                                                    );
                                                    return this.handleErrorAsResponse<TResponse>(err);
                                                })
                                              );

                                            } else {
                                              LogService.debug(
                                                `Utilizzo la cache non scaduta per la chiamata ${method} ${url}`, cycledRequestPlain
                                              );
                                              return of(resFromCache)
                                            }
                                        })
                                    );

                                } else {

                                    // Se la cache salvata è scaduta
                                    const timeoutCacheMs = hasCache && cacheOptions.enableTimeout ?
                                        (cacheOptions.timeoutMillisecond ?? TIMEOUT_CACHE) :
                                        0

                                    // Effettuo la chiamata, passando anche i parametri per l'eventuale risposta per il timeout
                                    return this.sendRequest(
                                        method,
                                        url,
                                        requestOptions,
                                        retryRequest,
                                        timeoutCacheMs,
                                        this.responseCache.get(
                                            responseType,
                                            url,
                                            cycledRequestPlain ? JSON.stringify(cycledRequestPlain) : null,
                                            this.onlineService.isOnline,
                                            WITHOUT_EXPIRATION_TIME,
                                            null,
                                            true,
                                            cacheOptions.tenantBarrier,
                                            cacheOptions.enterpriseBarrier,
                                            cacheOptions.userBarrier,
                                            cacheOptions.overrideBarrierValues
                                        ).pipe(tap((res) => {
                                            LogService.debug(`Uso la cache a causa del timeout di ${timeoutCacheMs} per la chiamata ${method} ${url}`, cycledRequestPlain);
                                        }))
                                    ).pipe(
                                        switchMap((response) => {
                                            LogService.debug(`${method} ${url}`, response, url, requestOptions);

                                            const res = this.handleResponse<TResponse>(
                                              responseType, response, false
                                            );

                                            // Se non devo bypassare la cache
                                            if (!cacheOptions.bypass) {

                                              // Se la chiamata è andata a buon fine
                                              if (res?.errors?.length == 0 && res?.operationSuccedeed == true) {
                                                // Aggiorno la cache per l'offline/timeout
                                                this.responseCache.set(
                                                  responseType,
                                                  url,
                                                  response,
                                                  cycledRequestPlain ? JSON.stringify(cycledRequestPlain) : null,
                                                  cacheOptions.force,
                                                  cacheOptions.tenantBarrier,
                                                  cacheOptions.enterpriseBarrier,
                                                  cacheOptions.userBarrier,
                                                  cacheOptions.overrideBarrierValues
                                                ).subscribe((res) => {
                                                    // if (this.responseCacheWebworkerSettings?.showLogs) {
                                                    //     LogService.debug('Risultato salvataggio cache: ' + res, cycledRequestPlain)
                                                    // }
                                                });
                                              }
                                            }
                                            return of(res);
                                        }),
                                        catchError((err) => {
                                            LogService.warn(
                                                `${method} ${url} failed`,
                                                err,
                                                url,
                                                requestOptions
                                            );
                                            return this.handleErrorAsResponse<TResponse>(err);
                                        })
                                    );
                                }
                              })
                            );
                        })
                    )
            } else {

                // Se ho attivo il bypass della cache
                return this.sendRequest(method, url, requestOptions, retryRequest).pipe(
                    switchMap((response) => {
                        LogService.debug(`${method} ${url}`, response, url, requestOptions, cycledRequestPlain);
                        return of(this.handleResponse<TResponse>(
                            responseType, response, false
                        ));
                    }),
                    catchError((err) => {
                        LogService.warn(
                            `${method} ${url} failed`,
                            err,
                            url,
                            requestOptions,
                            cycledRequestPlain
                        );
                        return this.handleErrorAsResponse<TResponse>(err);
                    })
                );
            }
        } else {
            // Sono offline
            return this.responseCache.check(
                responseType,
                url,
                cycledRequestPlain ? JSON.stringify(cycledRequestPlain) : null,
                this.onlineService.isOnline,
                cacheOptions.expirationTime,
                null,
                cacheOptions.force,
                cacheOptions.tenantBarrier,
                cacheOptions.enterpriseBarrier,
                cacheOptions.userBarrier,
                cacheOptions.overrideBarrierValues
            ).pipe(
                switchMap((isInCache) => {
                    // Ho la cache per l'offline
                    if (isInCache) {
                        return this.responseCache.get(
                            responseType,
                            url,
                            cycledRequestPlain ? JSON.stringify(cycledRequestPlain) : null,
                            this.onlineService.isOnline,
                            cacheOptions.expirationTime,
                            null,
                            cacheOptions.force,
                            cacheOptions.tenantBarrier,
                            cacheOptions.enterpriseBarrier,
                            cacheOptions.userBarrier,
                            cacheOptions.overrideBarrierValues
                        ).pipe(switchMap((cachedData) => {
                            LogService.debug(`Used cache for ${url}`);
                            return of(this.handleResponse<TResponse>(
                                responseType, cachedData, false
                            ));
                        }))

                    } else {

                        // Non ho la cache per l'offline
                        const err: any = new Error(
                            `No cache found for ${method} ${url} with sended data.`
                        );
                        LogService.warn(
                            `${method} ${url} failed in offline mode`,
                            err,
                            url,
                            cycledRequestPlain,
                            cacheOptions
                        );
                        return this.handleErrorAsResponse<TResponse>(err);
                    }
                })
            );
        }

    }

    private handleResponse<TResponse>(responseTypeOrInstance, response, fromInstance = false, useWorker = false): TResponse {

        return (fromInstance ?
            plainToClassFromExist<TResponse, object>(responseTypeOrInstance as TResponse, response, { strategy: 'excludeAll' }) :
            plainToClass<TResponse, object>(responseTypeOrInstance as ClassConstructor<TResponse>, response, { strategy: 'excludeAll' })) as TResponse;
    }

    private shouldRetry(error: HttpErrorResponse) {
        // Se viene ritornato un 50X aumento il tempo di retry a 15 secondi
        if (error.status >= 500) {
          return timer(15000); // Adding a timer from RxJS to return observable to delay param.
        } else { // Di norma il tempo di retry è 3 secondi
          return timer(3000)
        }
        // throw error;
      }

    private sendRequest(
        method: string,
        url: string,
        options: any = {},
        retryRequest = 0,
        timeoutCache = 0,
        timeoutCacheFallbackObservable: Observable<any> = null
    ): Observable<any> {
        options.observe = options.observe || 'response';
        return this.httpService.request(method, url, options).pipe(
            timeoutCache > 0 ?
                timeout({
                    first: timeoutCache,
                    with: () => timeoutCacheFallbackObservable.pipe(map((d) => {
                        if (d) {
                            d['fromTimeoutCache'] = 'true';
                        }
                        return d;
                    }))
                }) :
                identity,



            // identity vuol dire che x => x, ripassa l'obs esattamente senza modifiche
            retryRequest > 0 ? retry({
                count: retryRequest,
                delay: this.shouldRetry
            }) : identity,
            map<any, any>((response: HttpResponse<any>) => {
                if (response && response['fromTimeoutCache'] === 'true') {
                    delete response['fromTimeoutCache'];
                    return response;
                }

                if (options.observe === 'events') {
                    return this.extractJson(response, true);
                } else if (options.responseType === 'text') {
                    return this.extractString(response);
                } else {
                    return this.extractJson(response);
                }
            }),
            shareReplay()
        );
    }

    private extractString(res: HttpResponse<string>) {

        let bodyText = '';

        if (res.status < 200 || res.status >= 300) {
            bodyText = 'Bad response status: ' + res.status;
        } else {
            // if there is some content, parse it
            if (res.status !== 204) {
                bodyText = res.body;
            }
        }

        return bodyText;
    }

    private extractJson(res: HttpResponse<any>, returnAll = false) {

        let body = {};

        if (res.status < 200 || res.status >= 300) {
            // bodyText = 'Bad response status: ' + res.status;
            body = {
                OperationSuccedeed: false,
                Errors: [{ Message: 'Bad response status: ' + res.status }],
                Informations: []
            };

        } else {
            // if there is some content, parse it
            if (res.status !== 204) {
                body = JsonNetDecycle.retrocycle(returnAll ? res : res.body);
                body = returnAll ? res : res.body;
            }

        }

        // TODO: Se operationSucceded = false, prova a parsare la response

        return body;
    }

    private handleErrorAsResponse<TResponse extends ServiceResponse>(error: any): Observable<TResponse> {

        const serviceResponse = new ServiceResponse();

        return this.handleErrorAsResponseWithInstance(error, serviceResponse as TResponse);
    }

    private handleErrorAsString(error: HttpErrorResponse): Observable<string> {

        // TODO: Approfondire se possiamo valorizzare ulterioremente l'errore
        let errorMessage: string = error.error || 'ServiceAgent Error';

        if (error.status) {
            errorMessage += ' status: ' + error.status;
        }

        return of(errorMessage);
    }

    private blobToString(b) {
        var u, x;
        u = URL.createObjectURL(b);
        x = new XMLHttpRequest();
        x.open('GET', u, false); // although sync, you're not fetching over internet
        x.send();
        URL.revokeObjectURL(u);
        return x.responseText;
    }

    private handleErrorAsResponseWithInstance<TResponse extends ServiceResponse>(
        error: HttpErrorResponse, responseInstance: TResponse): Observable<TResponse> {
        if (error.status === 403) {
            const errCode = error.statusText || MessageCodes.UnauthorizedHttpStatusCode;
            const errMsg = error.error || MessageResourceManager.Current.getMessage(errCode);
            const err = new BaseError();
            err.code = errCode;
            err.description = errMsg;
            const responseUnauthorized: ServiceResponse = new ServiceResponse();
            responseUnauthorized.errors.push(err);
            return of(responseUnauthorized as TResponse);
        }

        if (error.status === 404) {
            const errCode = MessageCodes.NotFoundHttpStatusCode;
            const errMsg = MessageResourceManager.Current.getMessage(errCode);
            const err = new BaseError();
            err.code = errCode;
            if (error?.error?.text){
                err.description = this.blobToString(error.error);
            } else {
                err.description = errMsg;
            }

            const responseNotFound: ServiceResponse = new ServiceResponse();
            responseNotFound.errors.push(err);
            return of(responseNotFound as TResponse);
        }

        const messageError = new BaseError();

        // TODO: Approfondire se possiamo valorizzare ulterioremente l'errore
        let errorMessage = '';
        if (error.error && error.error.Message) {
            errorMessage += error.error.Message;
        } else if (error.message) {
            errorMessage += error.message;
            messageError.stackTrace = (error as Error).stack;
        } else {
            errorMessage = 'ServiceAgent Error';
        }

        messageError.description = errorMessage;

        if (error.status) {
            messageError.description += ' status: ' + error.status;
        }
        responseInstance.operationSuccedeed = false;
        responseInstance.informations = [];
        responseInstance.errors = [messageError];

        return of(responseInstance);
    }
}
